import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable, throwError } from "rxjs";
import { isUndefined } from "lodash-es";
import { tap } from "rxjs/operators";

export interface ResetPasswordParams {
    accountId: string;
    token: string;
    password: string;
}

@Injectable()
export class SiteIdentityModelService {
    constructor(private connection: ConnectionFactoryService) {
    }

    register(registerData: object, useFullPath: boolean = false): Observable<any> {
        return this.connection
            .service("site", useFullPath)
            .setPath("/identity/register")
            .post(undefined, registerData, undefined, {withCredentials: true})
            .pipe(
                tap((response) => {
                    if (response?.jwt) {
                        (<any>window).EC_JWT = response.jwt;
                    }
                })
            );
    }

    login(loginData: object, useFullPath: boolean = false): Observable<any> {
        return this.connection
            .service("site", useFullPath, false)
            .setPath("/identity/login")
            .post(undefined, loginData, undefined, {withCredentials: true})
            .pipe(
                tap((response) => {
                    if (response?.jwt) {
                        (<any>window).EC_JWT = response.jwt;
                    }
                })
            );
    }

    logout(useFullPath: boolean = false): Observable<any> {
        return this.connection
            .service("site", useFullPath)
            .setPath("/identity/logoutdirect")
            .post();
    }

    changeRtcRole(params: { accountId: number, rtcRole?: boolean, skypeRole?: boolean }): Observable<any> {
        if (!params?.accountId || (isUndefined(params?.rtcRole) && isUndefined(params?.skypeRole))) {
            return throwError("Please send valid params");
        }
        return this.connection
            .service("site")
            .setPath(`/identity/changertcrole/`)
            .post(undefined, params);
    }

    forgotPassword(email: string, redirectionPath: string = "", redirectionOrigin: string = ""): Observable<any> {
        let params = {email, redirectionPath, redirectionOrigin};
        return this.connection
            .service("site")
            .setPath("/identity/forgotpassword")
            .post(undefined, params);
    }

    resetPassword(params: ResetPasswordParams): Observable<any> {
        return this.connection
            .service("site")
            .setPath("/identity/resetpassword")
            .post(undefined, params);
    }

    addAccountToOrganization(organizationId: number, accountId: number): Observable<void> {
        if (!accountId || !organizationId) {
            return throwError("accountId and organizationId are required");
        }
        return this.connection
            .service("site")
            .setPath(`/identity/accountorganization`)
            .put({accountId: accountId, organizationId: organizationId});
    }
}
